<template>
  <div class="overflow-x-auto">
    <form
      v-if="showSearch"
    >
      <div class="form-input relative w-2/3 sm:w-1/3 mb-6 flex items-center">
        <Icon
          width="24px"
          height="24px"
          view-box="0 0 24 24"
          class="text-gray-500"
        >
          <Search />
        </Icon>
        <input
          v-model="searchQuery"
          type="text"
          name="query"
          class="w-full focus:outline-none ml-3"
          placeholder="Search results"
        >
      </div>
    </form>

    <table class="w-full border-collapse text-gray-800">
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            :class="['font-normal text-gray-500 text-left text-xs pb-2 px-6 whitespace-no-wrap', columnsClasses(column.key)]"
          >
            <Tooltip
              v-if="Object.keys(tooltips).includes(column.key)"
              :content="tooltips[column.key]"
              class="pr-1 inline-flex items-center align-top"
            />
            <a
              href="javascript:;"
              :class="['inline-flex items-center uppercase', { 'font-semibold text-gray-600': column.sortable && (column.key === sortKey && sortOrder === 'asc') }]"
              @click="sortBy(column.key)"
            >
              <span>{{ column.key }}</span>

              <div
                v-if="column.sortable"
                class="px-2 text-gray-500"
              >
                <svg
                  :class="['fill-current h-4 w-4', { 'transform rotate-180 text-gray-600': column.key === sortKey && sortOrder === 'asc' }]"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
            </a>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(data, key) in filteredData"
          :key="key"
        >
          <TableCell>
            <CandidateProfileLink
              v-if="data.unlockedAt"
              :candidate-id="data.candidateId"
              class="flex items-center hover:text-secondary duration-300"
            >
              <Icon
                width="24px"
                height="24px"
                view-box="0 0 512 512"
                class="mr-4 text-gray-600"
              >
                <Survey />
              </Icon>
              <CandidateName>
                {{ data.name }}
              </CandidateName>
            </CandidateProfileLink>
            <div
              v-else
              class="flex items-center"
            >
              <Icon
                width="24px"
                height="24px"
                view-box="0 0 512 512"
                class="mr-4 text-gray-600"
              >
                <Survey />
              </Icon>
              <CandidateName>
                {{ data.name }}
              </CandidateName>
            </div>
          </TableCell>

          <TableCell>
            {{ data.test }}
          </TableCell>

          <template v-if="data.unlockedAt">
            <TableCell
              v-if="data.result || data.scores"
            >
              {{ formatResult(data) }}
            </TableCell>
            <TableCell v-else-if="data.personalityResult">
              <router-link
                class="hover:text-gray-600 duration-150"
                :to="{
                  name: 'personality-info',
                }"
              >
                {{ data.personalityResult }}
              </router-link>
            </TableCell>
            <TableCell
              v-else
            >
              0%
            </TableCell>

            <TableCell
              v-if="data.average"
              class="hidden sm:table-cell"
            >
              {{ data.average }}%
            </TableCell>
            <TableCell
              v-else
              class="hidden sm:table-cell"
            >
              N/A
            </TableCell>

            <TableCell
              v-if="data.answered !== null && data.questionsTotal"
              class="hidden sm:table-cell"
            >
              {{ data.answered }}/{{ data.questionsTotal }}
            </TableCell>
            <TableCell
              v-else
              class="hidden sm:table-cell"
            >
              N/A
            </TableCell>

            <TableCell
              v-if="data.time"
              class="hidden sm:table-cell"
            >
              {{ secondsToHuman(data.time) }}
            </TableCell>
            <TableCell
              v-else
              class="hidden sm:table-cell"
            >
              N/A
            </TableCell>
          </template>
          <template v-else>
            <TableCell>
              <router-link
                class="whitespace-no-wrap text-secondary hover:text-secondary-400"
                :to="{
                  name: 'settings-plan',
                }"
              >
                Upgrade to view
                <Icon
                  width="20px"
                  height="20px"
                  view-box="0 0 24 24"
                  class="ml-2"
                >
                  <Lock />
                </Icon>
              </router-link>
            </TableCell>

            <TableCell>
            </TableCell>

            <TableCell>
            </TableCell>

            <TableCell>
            </TableCell>
          </template>
        </tr>
      </tbody>
    </table>

    <Pagination
      class="mt-4"
      :total-pages="paginationData.totalPages"
      :per-page="paginationData.perPage"
      :current-page="paginationData.currentPage"
      @pagechanged="$emit('pagechanged', $event)"
    />
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Search from '@components/Icons/Search'
import Survey from '@components/Icons/Survey'
import Lock from '@components/Icons/Lock'
import TableCell from '@components/TableCell'
import Pagination from '@components/Pagination'
import Tooltip from '@components/Tooltip'
import CandidateProfileLink from '@components/Candidates/CandidateProfileLink'
import CandidateName from '@components/Candidates/CandidateName'

import { secondsToHuman } from '@utils/time'
import { truncateString } from '@utils/truncate'

export default {
  components: {
    Icon,
    Search,
    Survey,
    Lock,
    TableCell,
    Pagination,
    Tooltip,
    CandidateProfileLink,
    CandidateName
  },

  props: {
    columns: {
      type: Array,
      default: null
    },
    tableData: {
      type: Array,
      default: null
    },
    jobs: {
      type: Array,
      default: null
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: ''
    },
    paginationData: {
      type: Object,
      default: null
    },
    sortData: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      truncateString,
      secondsToHuman,

      searchQuery: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',

      tooltips: {
        result: 'This is the raw percentage test score of the candidate. If the candidate answered 10 out of 20 questions correctly, this score would be 50%.',

        average: 'This is the average raw percentage test score of all candidates who’ve ever taken the test. This includes all demographics and candidates from all locations.',

        answered: 'This is the total number of questions the candidate answered. So, if the test had 20 questions and they answered half, this would show 10/20.',

        time: 'This is the amount of time taken by the candidate to submit the test. If the test has a 20min time limit, the time taken will always be 20mins or less.'
      }
    }
  },

  computed: {
    /**
     * Rename to match the column headings
     *
     * @return {Array}
     */
    filteredData() {
      return this.tableData.map(({ candidate, job, exam, result, scorePercentage, scores, questionsAnswered, speedCompleted, questionsTotal, locked, createdAt, unlockedAt }) =>
        ({
          candidateId: candidate.id,
          name: `${candidate.firstName} ${candidate.surname}`,
          job: job ? job.name : null,
          test: exam ? exam.name : '',
          personalityResult: result,
          scores,
          result: scorePercentage,
          average: exam ? exam.averageScorePercentage : 0,
          answered: questionsAnswered,
          time: speedCompleted,
          questionsTotal,
          locked,
          createdAt,
          unlockedAt
        })
      )
    }
  },

  created() {
    if (this.sortData) {
      this.sortKey = this.sortData.key
      this.sortOrder = this.sortData.order
    }
  },

  methods: {
    /**
     * Trigger sort by key
     *
     * @param {string} key
     */
    sortBy(key) {
      if (this.sortOrder === 'asc') {
        this.sortOrder = 'desc'
      } else {
        this.sortOrder = 'asc'
      }

      this.$emit('sort', { key, order: this.sortOrder })
    },

    /**
     * Formats a result into a percentage, or returns ‘Yes’ if only scores
     *
     * @param {Object}
     * @return {string}
     */
    formatResult(data) {
      if (data.result) {
        return `${data.result}%`
      }
      if (data.scores) {
        return 'Yes'
      }
    },

    /**
     * Build classes for column management
     *
     * @param {string}
     * @return {string}
     */
    columnsClasses(columnKey) {
      const columns = ['job', 'average', 'answered', 'time']
      if (columns.includes(columnKey)) {
        return 'hidden sm:table-cell'
      }
    }
  }
}
</script>
